@import '~@astrid/components/src/scss/utils';

.forgot {
  cursor: pointer;
  margin: 1rem 0;

  &:hover {
    color: $grey;
  }
}
