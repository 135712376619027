@import '~@astrid/components/src/scss/globals';

@font-face {
  font-family: 'Lexend';
  src: url('~@astrid/components/src/fonts/LexendDeca-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  src: url('~@astrid/components/src/fonts/WorkSans-ExtraBold.ttf') format('truetype');
}

html {
  font-size: 13px;
}

dl {
  margin: 0;
}

p {
  margin: 0;
}

/* Astrid global overrides */
body {
  background-color: #f3f7ff;
  overflow: auto;
}
#root {
  height: auto;
}
