@import '~@astrid/components/src/scss/utils';

.link {
  @include chip-styling(true);
}

.activeLink,
.activeLink:hover {
  background-color: $cobalt-blue;
  color: white;

  & svg {
    fill: white;
  }
}
